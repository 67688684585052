<template>
  <div class="large-page">
    <breadcrumbs>
      <div class="d-flex flex-wrap flex-grow-1 justify-end align-center mt-3 mt-md-0 ml-md-4">
        <!-- search input -->
        <v-text-field
          v-model.trim="searchQuery"
          dark
          dense
          outlined
          hide-details
          label="Search Users"
          class="mr-sm-3 mr-md-6"
          append-icon="search"
          @click:append="handlePagination(1)"
          @keypress.enter="handlePagination(1)"
        />

        <div class="mt-3 mt-sm-0 d-flex">
          <!-- add new user CTA -->
          <v-btn depressed class="mr-2 mr-md-3" color="white" @click="$router.push({ name: 'Admin/Users/Create' })">
            <v-icon color="secondary" left> add </v-icon>

            User
          </v-btn>

          <!-- Refresh current list items CTA -->
          <v-btn depressed class="mr-2 mr-md-3" color="white" @click="fetchItems">
            <v-icon color="secondary" left> refresh </v-icon>

            Refresh
          </v-btn>

          <!-- Show all the columns in the table -->
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed class="mr-2 mr-md-3" color="white" v-bind="attrs" v-on="on">
                <v-icon> view_column </v-icon>
              </v-btn>
            </template>

            <!-- For each column item, show a list item -->
            <v-list>
              <v-list-item v-for="item in columns" :key="item.value">
                <v-list-item-action>
                  <v-simple-checkbox v-model="item.enabled" color="primary" />
                </v-list-item-action>

                <v-list-item-title class="cursor-pointer" @click="item.enabled = !item.enabled">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- Show the sort options -->
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed color="white" v-bind="attrs" v-on="on">
                <v-icon> swap_vert </v-icon>
              </v-btn>
            </template>

            <v-card max-width="180">
              <v-card-text class="pb-0">
                <!-- Show the inputs to sort it with -->
                <v-select label="Sort By" v-model="sortBy" :items="sortByOptions" @change="handleSortOptions" />

                <!-- Show the inputs to order it by -->
                <v-select label="Sort Order" v-model="sortOrder" :items="sortOrderOptions" @change="handleSortOptions" />
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
    </breadcrumbs>

    <template v-if="apiResponse">
      <!-- Show possible filters -->
      <div class="px-3 py-4 mt-4 white">
        <!-- Show the filter options -->
        <filter-options />

        <v-divider class="mb-3 mt-4"></v-divider>

        <!-- Show the applied filters -->
        <template v-if="appliedFilters.length">
          <applied-filters :filters="appliedFilters" @remove="handleRemove" />
        </template>
      </div>

      <!-- Show the results table -->
      <v-data-table
        :headers="tableHeaders"
        :items="apiResponse.data"
        :server-items-length="apiResponse.total"
        class="mt-4 box-shadow-soft admin-users-data-table"
        :page="configPage"
        :items-per-page="configItemsPerPage"
        @update:page="handlePagination"
        @update:items-per-page="handlePerPage"
        mobile-breakpoint="100"
      >
        <!-- To show current credits/balance -->
        <template v-slot:item.balance="{ item }">
          <span :class="{ 'red--text': item.balance < 0 }">
            {{ item.balance / constants.conversionRate }}
          </span>
        </template>

        <!-- To show a flag before the country name -->
        <template v-slot:item.country="{ item }">
          <v-img v-if="item.country" height="13" width="20" :src="proxyUrl(`https://flagcdn.com/w20/${item.country.toLowerCase()}.png`, true)"></v-img>
        </template>

        <!-- To show a relative time for last active -->
        <template v-slot:item.last_active_at="{ item }">
          <span class="cursor-pointer" :title="item.last_active_at">
            {{ item.last_active_at_relative }}
          </span>
        </template>

        <!-- Show whether or not this user is allowed to use -->
        <template v-slot:item.is_active="{ item }">
          {{ item.is_active ? "Active" : "Inactive" }}
        </template>

        <!-- Show if the user is expired or will get expired -->
        <template v-slot:item.valid_to="{ item }">
          <!-- If there's the item value -->
          <template v-if="item.valid_to">
            <span :title="dayjs(item.valid_to).format('LLL')">
              <!-- If it was in past or future -->
              {{ dayjs().isBefore(dayjs(item.valid_to)) ? `Expires in ${dayjs().to(dayjs(item.valid_to))}` : "Expired" }}
            </span>
          </template>
          <!-- Otherwise -->
          <template v-else> - </template>
        </template>

        <!-- Show the action CTAs -->
        <template v-slot:item.action="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              small
              depressed
              class="mr-3"
              color="primary"
              @click="$router.push({ name: 'Admin/Users/Edit', params: { userId: item.id } })"
            >
              Edit
            </v-btn>

            <v-btn
              small
              depressed
              class="mr-3"
              color="primary"
              @click="$router.push({ name: 'Admin/Users/UsageByMonth', params: { userId: item.id } })"
            >
              Usage
            </v-btn>

            <v-btn
              small
              depressed
              color="primary"
              @click="loginAsTheUser(item.id)"
              :disabled="(item.valid_to && !dayjs().isBefore(dayjs(item.valid_to))) || !item.is_active"
            >
              Login
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
// Import child components
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")
const AppliedFilters = () => import(/* webpackChunkName: "applied-filters" */ "@/components/common/AppliedFilters.vue")
const FilterOptions = () => import(/* webpackChunkName: "pc-filter-options" */ "@/components/admin/FilterOptions.vue")

// Import local storage helper functions
import { localStorageManager, API_DATA, ADMIN_DATA } from "@/helpers/localStorageManager.js"

// @BUGFIX https://github.com/vuetifyjs/vuetify/issues/12224#issuecomment-894201049
import Ripple from "vuetify/lib/directives/ripple"

// Define a generator function for columns to be shown
const defaultColumns = () => [
  {
    text: "ID",
    value: "id",
    sortable: false,
    enabled: true
  },
  {
    text: "Name",
    value: "name",
    sortable: false,
    enabled: true
  },
  {
    text: "Email ID",
    value: "email",
    sortable: false,
    enabled: true
  },
  {
    text: "Mobile No.",
    value: "phone",
    sortable: false,
    enabled: true
  },
  {
    text: "Country",
    value: "country",
    sortable: false,
    enabled: true
  },
  {
    text: "Role",
    value: "role",
    sortable: false,
    enabled: true
  },
  {
    text: "Balance",
    value: "balance",
    sortable: false,
    enabled: true
  },
  {
    text: "Validity",
    value: "valid_to",
    sortable: false,
    enabled: false
  },
  {
    text: "Last Active At",
    value: "last_active_at",
    sortable: false,
    enabled: true
  },
  {
    text: "IP Address",
    value: "last_active_ip_address",
    sortable: false,
    enabled: true
  },
  {
    text: "Status",
    value: "is_active",
    sortable: false,
    enabled: true
  },
  {
    text: "Action",
    value: "action",
    sortable: false,
    enabled: true
  }
]

// Export the SFC
export default {
  // Name of the component
  name: "AdminUserIndex",

  // Register the components
  components: {
    Breadcrumbs,

    AppliedFilters,
    FilterOptions
  },

  // Register the directives
  directives: {
    Ripple
  },

  // Local data variables
  data: () => ({
    columns: defaultColumns(),

    // The columns that are sortable
    sortBy: "id",
    sortOrder: "desc",

    sortByOptions: [
      {
        text: "ID",
        value: "id"
      },
      {
        text: "Status",
        value: "is_active"
      },
      {
        text: "Validity",
        value: "valid_to"
      }
    ],

    sortOrderOptions: [
      {
        text: "Ascending",
        value: "asc"
      },
      {
        text: "Descending",
        value: "desc"
      }
    ]
  }),

  // Computed data variables
  computed: {
    /**
     * Return a list of columns to be shown
     *
     * @returns {Array}
     */
    tableHeaders() {
      return this.columns.filter((item) => item.enabled)
    },

    /**
     * The query object set in Vuex store
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["admin/users/query"]
    },

    /**
     * The entire paginated data
     *
     * @returns {Object}
     */
    apiResponse() {
      return this.$store.getters["admin/users/apiResponse"]
    },

    // Use getter and setter to link the value with vuex store
    searchQuery: {
      /**
       * Readonly search input value
       *
       * @returns {String}
       */
      get() {
        return this.query.search
      },

      /**
       * Write only function to modify the value in Vuex store
       *
       * @param {String} value
       * @returns {void}
       */
      set(value) {
        // update the query term
        this.$store.dispatch("admin/users/updateQuery", { key: "search", value })

        // reset the page number to 1
        this.$store.dispatch("admin/users/updateQuery", { key: "page", value: 1 })
      }
    },

    /**
     * Get the pagination page number from store
     */
    configPage: {
      get() {
        return this.$store.getters["config/get"]("admin-users-pagination-page")
      },

      set(value) {
        this.$store.dispatch("config/set", {
          key: "admin-users-pagination-page",
          value
        })
      }
    },

    /**
     * Get the pagination items per page from store
     */
    configItemsPerPage: {
      get() {
        return this.$store.getters["config/get"]("admin-users-pagination-items-per-page")
      },

      set(value) {
        this.$store.dispatch("config/set", {
          key: "admin-users-pagination-items-per-page",
          value
        })
      }
    },

    /**
     * Get the applied filters from vuex store
     *
     * @returns {Array}
     */
    appliedFilters() {
      return this.$store.getters[`admin/users/filters`].sort((a, b) => a.sort - b.sort)
    }
  },

  // Set watchers for data properties
  watch: {
    columns: {
      handler: (items) => {
        // Save it into the localStorage
        localStorageManager({
          name: ADMIN_DATA,
          key: "userColumns",
          value: items
        })
      },

      deep: true,
      immediate: false
    }
  },

  // Options API method functions
  methods: {
    /**
     * Update the pagination filter value in vuex store
     *
     * @param {String|Number} value
     * @returns {void}
     */
    handlePagination(value) {
      // Update the query value
      this.$store.dispatch("admin/users/updateQuery", { key: "page", value })

      // Also update the config value
      this.configPage = value

      // Make the network request
      this.fetchItems()
    },

    /**
     * Update the pagination filter value in vuex store
     *
     * @param {String|Number} value
     * @returns {void}
     */
    handlePerPage(value) {
      // Update the query value
      this.$store.dispatch("admin/users/updateQuery", { key: "perPage", value })

      // Also update the config value
      this.configItemsPerPage = value

      // Make the network request
      this.fetchItems()
    },

    /**
     * Update the sort filter values in vuex store
     *
     * @returns {void}
     */
    handleSortOptions() {
      this.$store.dispatch("admin/users/updateQuery", { key: "sortBy", value: this.sortBy })
      this.$store.dispatch("admin/users/updateQuery", { key: "sortOrder", value: this.sortOrder })

      this.fetchItems()
    },

    /**
     * Call the Vuex action to make network request
     *
     * @returns {void}
     */
    fetchItems() {
      this.$store.dispatch("admin/users/fetchItems")
    },

    /**
     * When the applied filter's remove icon is clicked
     *
     * @param {Object} data
     */
    handleRemove(data) {
      this.$store.dispatch(`admin/users/removeFilter`, data.id)
    },

    /**
     * Login as the user instead of current admin
     *
     * @param {Number} id
     * @returns {void}
     */
    async loginAsTheUser(id) {
      // Show the loader
      const loaderId = Symbol()

      this.$store.dispatch("loaders/add", loaderId)

      try {
        // Make the network request to the auth API
        const response = await axios({
          url: "/api/admin/login-as-user/" + id,
          method: "POST"
        })

        // Get the current admin's access token
        const currentAccessToken = getAccessToken()

        // Clear the localStorage state
        window.localStorage.clear()
        window.sessionStorage.clear()

        // Store the current token as the fallback token
        localStorageManager({
          name: API_DATA,
          key: "fallback_token",
          value: currentAccessToken
        })

        // Save the logged in user's token for new session
        localStorageManager({
          name: API_DATA,
          key: "access_token",
          value: response.data.token
        })

        // Save the URL to return back to
        localStorageManager({
          name: API_DATA,
          key: "redirect_after_logout",
          value: "/admin/users"
        })

        // Mark that the token is an admin
        localStorageManager({
          name: API_DATA,
          key: "is_admin",
          value: "yes"
        })

        // Now redirect to the dashboard
        window.location.href = "/dashboard"
      } catch (error) {
        // Log the error
        console.error(error)

        this.$store.dispatch("toasts/add", { text: "An error occurred, please try again." })
      } finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
      }
    }
  },

  /**
   * As soon as the data variables are ready
   *
   * @returns {void}
   */
  created() {
    // Check if the columns are there in the localStorage
    const items = localStorageManager({ name: ADMIN_DATA })?.userColumns

    // If the items are there
    if (items && items.length) {
      // Set the columns value
      this.columns = items
    }

    // Check if we don't have the configPage value
    if (!this.configPage) {
      // Set the configPage value to 1
      this.configPage = 1
    }

    // Check if we don't have the configItemsPerPage value
    if (!this.configItemsPerPage) {
      // Set the configItemsPerPage value to 10
      this.configItemsPerPage = 10
    }
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  mounted() {
    // only reload the data on mounted if it's null
    if (this.apiResponse === null) {
      this.fetchItems()
    }
  }
}
</script>

<style lang="stylus">
.admin-users-data-table thead tr th
  white-space nowrap !important
</style>
