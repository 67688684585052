var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"large-page"},[_c('breadcrumbs',[_c('div',{staticClass:"d-flex flex-wrap flex-grow-1 justify-end align-center mt-3 mt-md-0 ml-md-4"},[_c('v-text-field',{staticClass:"mr-sm-3 mr-md-6",attrs:{"dark":"","dense":"","outlined":"","hide-details":"","label":"Search Users","append-icon":"search"},on:{"click:append":function($event){return _vm.handlePagination(1)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handlePagination(1)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchQuery"}}),_c('div',{staticClass:"mt-3 mt-sm-0 d-flex"},[_c('v-btn',{staticClass:"mr-2 mr-md-3",attrs:{"depressed":"","color":"white"},on:{"click":function($event){return _vm.$router.push({ name: 'Admin/Users/Create' })}}},[_c('v-icon',{attrs:{"color":"secondary","left":""}},[_vm._v(" add ")]),_vm._v(" User ")],1),_c('v-btn',{staticClass:"mr-2 mr-md-3",attrs:{"depressed":"","color":"white"},on:{"click":_vm.fetchItems}},[_c('v-icon',{attrs:{"color":"secondary","left":""}},[_vm._v(" refresh ")]),_vm._v(" Refresh ")],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mr-md-3",attrs:{"depressed":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" view_column ")])],1)]}}])},[_c('v-list',_vm._l((_vm.columns),function(item){return _c('v-list-item',{key:item.value},[_c('v-list-item-action',[_c('v-simple-checkbox',{attrs:{"color":"primary"},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1),_c('v-list-item-title',{staticClass:"cursor-pointer",on:{"click":function($event){item.enabled = !item.enabled}}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" swap_vert ")])],1)]}}])},[_c('v-card',{attrs:{"max-width":"180"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-select',{attrs:{"label":"Sort By","items":_vm.sortByOptions},on:{"change":_vm.handleSortOptions},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('v-select',{attrs:{"label":"Sort Order","items":_vm.sortOrderOptions},on:{"change":_vm.handleSortOptions},model:{value:(_vm.sortOrder),callback:function ($$v) {_vm.sortOrder=$$v},expression:"sortOrder"}})],1)],1)],1)],1)],1)]),(_vm.apiResponse)?[_c('div',{staticClass:"px-3 py-4 mt-4 white"},[_c('filter-options'),_c('v-divider',{staticClass:"mb-3 mt-4"}),(_vm.appliedFilters.length)?[_c('applied-filters',{attrs:{"filters":_vm.appliedFilters},on:{"remove":_vm.handleRemove}})]:_vm._e()],2),_c('v-data-table',{staticClass:"mt-4 box-shadow-soft admin-users-data-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.apiResponse.data,"server-items-length":_vm.apiResponse.total,"page":_vm.configPage,"items-per-page":_vm.configItemsPerPage,"mobile-breakpoint":"100"},on:{"update:page":_vm.handlePagination,"update:items-per-page":_vm.handlePerPage},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text': item.balance < 0 }},[_vm._v(" "+_vm._s(item.balance / _vm.constants.conversionRate)+" ")])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [(item.country)?_c('v-img',{attrs:{"height":"13","width":"20","src":_vm.proxyUrl(("https://flagcdn.com/w20/" + (item.country.toLowerCase()) + ".png"), true)}}):_vm._e()]}},{key:"item.last_active_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"cursor-pointer",attrs:{"title":item.last_active_at}},[_vm._v(" "+_vm._s(item.last_active_at_relative)+" ")])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? "Active" : "Inactive")+" ")]}},{key:"item.valid_to",fn:function(ref){
var item = ref.item;
return [(item.valid_to)?[_c('span',{attrs:{"title":_vm.dayjs(item.valid_to).format('LLL')}},[_vm._v(" "+_vm._s(_vm.dayjs().isBefore(_vm.dayjs(item.valid_to)) ? ("Expires in " + (_vm.dayjs().to(_vm.dayjs(item.valid_to)))) : "Expired")+" ")])]:[_vm._v(" - ")]]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'Admin/Users/Edit', params: { userId: item.id } })}}},[_vm._v(" Edit ")]),_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'Admin/Users/UsageByMonth', params: { userId: item.id } })}}},[_vm._v(" Usage ")]),_c('v-btn',{attrs:{"small":"","depressed":"","color":"primary","disabled":(item.valid_to && !_vm.dayjs().isBefore(_vm.dayjs(item.valid_to))) || !item.is_active},on:{"click":function($event){return _vm.loginAsTheUser(item.id)}}},[_vm._v(" Login ")])],1)]}}],null,false,1710115161)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }